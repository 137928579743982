import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #f5f5f5;
  display: inline-block;
  white-space: nowrap;
  margin: 1vh 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-weight: 500;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 12px 0;
    font-size: 1.1rem;
    z-index: 6;
  }
  @media (min-width: 1300px) {
    font-weight: 300;
  }
`
const EnNavbarLinks = () => {
  return (
    <>
      <NavItem to="/en_empresa">Company</NavItem>
      <NavItem to="/en_aplicaciones">Applications</NavItem>
      <NavItem to="/en_maquinas">Machines</NavItem>
      <NavItem to="/en_grabados">Engravings</NavItem>
      <NavItem to="/en_peliculas">foils</NavItem>
      <NavItem to="/en_demos">Demos</NavItem>
      <NavItem to="/en_contacto">Contact</NavItem>
    </>
  )
}

export default EnNavbarLinks
