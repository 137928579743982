
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const HeaderStyled = styled.div`
margin: 0 auto;
padding: 1vh 2vw;
color: #ffffff;
@media (max-width: 768px) {
  padding: 1vh 1vw;
  color: #ffffff;
}
`

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#636161`
    }}
  >
    <HeaderStyled>
      <h1>
        
          {siteTitle}
      
      </h1>
    </HeaderStyled>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
