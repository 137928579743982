import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import Imagen500esemiautomatica from '../components/imageComponents/imagen5000Esemiautomatica'

const IndexPage = () => (
  <EnLayout>
    <SEO title='5000 E SEMIAUTOMÁTICA' />
    <Header siteTitle='OR-PRINTER Semi-automatic with automatic tray 5020' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
          <Imagen500esemiautomatica />
        </div>
        <div className='col-12 col-md-6 conmargen'>
          <h3 className={Estilos.username}>Technical specifications:</h3>
          <ul className='aplicaciones'>
            <li> Semi-automatic machine driven by electric motor. </li>
            <li> Motorized tray or pedal work systems. </li>
            <li> Power supply: 230 V. </li>
            <li> Maximum electrical power: 750 W. </li>
            <li> Maximum Pressure: 1300 kgs. </li>
            <li> Maximum piece height: 65 mm. </li>
            <li> Maximum printing area: 200 X 100 mm. </li>
            <li> Work rate of up to 1300 impressions / hour. </li>
            <li> Digital temperature controller P.I.D. up to 300 & deg; C. </li>
            <li> Menu control screen with 20 memories </li>
            <li>
              Timer controlled electric motor film advance
              electronic.
            </li>
            <li> Self-tilting head, adjustable in height and inclination. </li>
            <li> Progressive, backward and totalizer digital counter. </li>
            <li> Weight: 67 Kg. </li>
            <li> Dimensions: Height: 75 cms. Width: 50 cms. Depth: 68 cms. </li>
          </ul>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
